var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{attrs:{"elevation":"0","color":"primary","to":"/role/create","disabled":_vm.isDeleting}},[_vm._v("新しい権限を作成")]):_vm._e()],1),_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"loading":_vm.roles.isLoading,"headers":_vm.headers,"items":_vm.roles.roles,"items-per-page":-1,"height":_vm.tableHeight,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.roleName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/role/" + (item.roleId))}},[_vm._v(" "+_vm._s(item.roleName)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","to":{ name: 'Administrators', params: { role: item.roleId } },"loading":_vm.isDeleting,"disabled":!item.isInUse}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v("管理者一覧")])]),(_vm.myRoleSettings.createAndUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":!item.isDefault,"loading":_vm.isDeleting,"to":{ name: 'EditRole', params: { roleId: item.roleId } }}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("編集")])]):_vm._e(),(_vm.myRoleSettings.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":!item.isDefault || item.isInUse,"loading":_vm.isDeleting},on:{"click":function($event){return _vm.deleteRole(item)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e()]}}],null,true)})],1)],(_vm.prevPage)?_c('v-btn',{staticClass:"mt-5",attrs:{"elevation":"0","color":"primary","to":_vm.prevPage,"disabled":_vm.isDeleting}},[_vm._v("戻る")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }