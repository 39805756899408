






























































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { RoleItem } from '@/admin/roles';
import { useRoles } from '@/composition/roles';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'Roles',
  components: {
    FcRoleLoading,
  },
  props: {
    prevPage: {
      type: String,
      required: false,
    },
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('roles'));

    const roles = useRoles();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();

    const headers = [
      { text: '権限名', value: 'roleName' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    const isDeleting = ref(false);

    const deleteRole = async (item: RoleItem) => {
      if (!(await confirmDialog(`${item.roleName} を本当に削除しますか？`))) return;
      try {
        isDeleting.value = true;
        await roles.deleteRole(item.roleId);
        await roles.getRoles();
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      } finally {
        isDeleting.value = false;
      }
    };

    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    return {
      pageTitle: '管理者権限',
      myRoleSettings,
      roles,
      headers,
      isDeleting,
      deleteRole,
      tableHeight,
    };
  },
});
